import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import NavbarOut from "../containers/AppMinimal/NavbarOut";

const PrivacyPolicy = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Terms & Conditions"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <NavbarOut/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1>PRIVACY POLICY</h1>
                            <h3>Last updated 23 MAY 2023 </h3>

                            <p>Welcome! You are now part of the secret-sms.com (herein secret-sms.com, we,
                                us,our) community. We henceforth commit to not only protect your personal
                                information but also your right to privacy. All concerns and queries about our privacy
                                policy and/or use of personal information, please contact us at support@secret-
                                sms.com.</p>
                            <p>This website secret-sms.com (herein the Website), and its services (herein the
                                Services, which include the Website), will protect your privacy. By using this Website
                                and its Services you acknowledge that you will provide us with your personal
                                information. How and what information is collected and how it is treated is herein
                                explained. It is our firm belief that it is not only within your rights, but in your
                                best interest as well to carefully read this privacy notice. Should you not be in full
                                agreement with this privacy policy please cease use of Services immediately.</p>
                            <p>All information collected through the Services include not only the Website but all and
                                any related events and services, sales and marketing tools.</p>
                            <h2>Table of Contents</h2>
                            <ol>
                                <li>1. INFORMATION COLLECTION</li>
                                <li>2. HOW INFORMATION IS STORED AND TREATED</li>
                                <li>3. INFORMATION SHARING WITH THIRD PARTIES</li>
                                <li>4. USE OF COOKIES AND OTHER TECHNOLOGIES</li>
                                <li>5. USE OF GOOGLE MAPS AND/OR PLATFORM APIS</li>
                                <li>6. PRIVATE SOCIAL LOGINS</li>
                                <li>7. LENGTH OF STORAGE OF INFORMATION</li>
                                <li>8. YOUR INFORMATION IS SAFE WITH US</li>
                                <li>9. INFORMATION FROM MINORS</li>
                                <li>10. YOUR PRIVACY RIGHTS</li>
                                <li>11. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                                <li>12. SPECIFIC PRIVACY RIGHTS FOR CALIFORNIA RESIDENTS</li>
                                <li>13. PRIVACY POLICY UPDATES</li>
                                <li>14. CONTACT US ABOUT THIS NOTICE</li>
                                <li>15. REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU</li>
                            </ol>
                            <h2>1. INFORMATION COLLECTION</h2>
                            <p>All personal information collected depends solely on the interactions with the Website
                                and is voluntarily provided by the user upon registration on the website, and/or when
                                contacting the Website, using the Services provided and any and all products. Personal
                                information provided by you that we collect may include the following: names; phone
                                numbers; email addresses; passwords; billing addresses; debit/credit card numbers;
                                and other similar information.</p>
                            <p>All personal information that you provide must be true, complete and accurate, and you
                                must notify us of any changes to such personal information.</p>
                            <h3>Payment Data</h3>
                            <p>Necessary data may be collected to process the User’s payment of purchases on the
                                Website. Information collected may include: credit card number, and/or the security
                                code associated with the card or other form of payment used. All payment data is
                                stored by Stripe. Please find their privacy notice <a
                                    href="https://stripe.com/privacy">here</a>.</p>
                            <h3>Social Media Login Data</h3>
                            <p>Login data may be collected should the User choose to register on the Website using
                                their existing social media account details, like Facebook, Twitter, or any other social
                                media account. Please refer to section “6 PRIVATE SOCIAL LOGINS” of this notice for
                                further information on how this information is treated.</p>

                            <h2>Automatically Collected Information</h2>
                            <p>To guarantee the security and operation of the Website, as well as for internal reporting
                                and analytics purposes, there is certain information that is automatically collected
                                when
                                visiting, or using, or navigating the Website. Information collected may include device
                                and usage information, IP address, country, location, browser and device
                                characteristics, operating system, language preferences, referring URLs, interactions of
                                the User with the Website, and other technical information. This information does not
                                and will not reveal the Users identity.</p>
                            <p>Additional information collected through cookies and/ or similar technologies may
                                include:</p>
                            <ul>
                                <li><strong>Login and interaction data:</strong> For purposes of performance and
                                    service-related
                                    diagnostics, servers may automatically collect information when the USer
                                    accesses or uses the Website such as log files. The information collected may
                                    include IP address, device information, browser type and settings, date/time
                                    stamps, pages and files viewed, searches, system activity, error reports
                                    (sometimes called crash dumps), and hardware settings.
                                </li>
                                <li><strong>Device Data:</strong> This may include information about the device used to
                                    access the
                                    Website. Depending on the device used (computer, tablet, phone, or any other
                                    device) it may also include information such as IP address (or proxy server),
                                    device and application identification numbers, location, browser type, hardware
                                    model, Internet service provider and/or mobile carrier, operating system and
                                    system configuration information.
                                </li>
                                <li><strong>Location Data:</strong> This includes information about your devices
                                    location. The accuracy of this information depends on the type and settings of the
                                    device used to access the Website. The Website may use GPS and other technologies
                                    based on IP Addresses to collect geolocation data to provide the User’s current
                                    location. The user may deny access to location by disabling the Location setting
                                    on the device used to access the server. Please bear in mind that by disabling
                                    the location feature, certain aspects of the Services of the Website may become
                                    unavailable.
                                </li>
                            </ul>
                            <h1>2. HOW INFORMATION IS STORED AND TREATED</h1>
                            <p>Personal information collected is voluntarily provided via the User when interacting with
                                the Website. Information gathered may be used in relation to legitimate business
                                purposes of the Website. For further understanding of the contract herein consented on
                                by the user in regards to the information collected and used by the Website please
                                refer to the points listed below.</p>
                            <p>Information is collected and used for the following reasons: </p>
                            <ol>
                                <li>
                                    1. To expedite the process of creating an account and/or login. All third-party
                                    linked
                                    accounts will require the Website to use the information provided by the User to
                                    facilitate account creation and/or login. For further information on how third-party
                                    social logins are handled please refer to section 6 of this notice.
                                </li>
                                <li>
                                    2. For testimonials. The Website posts testimonials regarding the Services provided.
                                    This may include personal information (such as name and testimonial) provSaid
                                    consent may be withdrawn, at any time by the User by simply contacting the Website.
                                </li>
                                <li>
                                    3. For feedback - The Website may contact the user to request feedback on Services
                                    provided.
                                </li>
                                <li>
                                    4. To enable user-to-user communications. In the event both parties provide consent
                                    to
                                    the Website, information may be used to facilitate user-to-user communications.
                                </li>
                                <li>
                                    5. To manage user accounts. Information may be used to help manage and maintain the
                                    User’s account.
                                </li>
                                <li>
                                    6. To provide administrative information. The Website may use Personal information
                                    to
                                    provide the user with product, service and new feature information and/or
                                    information about changes to terms, conditions, and policies.
                                </li>
                                <li>
                                    7. To protect the Websites’ Services. Information may be used to keep the Website
                                    safe
                                    and secure.
                                </li>
                                <li>
                                    8. To enforce the terms, conditions and policies of the Website for business
                                    purposes
                                    and to comply with legal and regulatory requirements or in connection with the
                                    contract herein described.
                                </li>
                                <li>
                                    9. For legal reasons. In the event the Website receives a subpoena or other legal
                                    requests, it may be necessary to inspect the data stored to answer to the best of
                                    the Website's abilities within the legal requirements.
                                </li>
                                <li>
                                    10. For marketing and promotional purposes. The Website and/or third-party marketing
                                    partners may use the personal information provided in accordance with the User’s
                                    preferences to further provide information. The user may withdraw consent of
                                    marketing emails at any time by simply contacting the Website.
                                </li>
                                <li>
                                    11. Provide targeted advertisement. The Website may use information provided to
                                    exhibit
                                    personalized content and advertising attune with Users' interests.
                                </li>
                            </ol>


                            <h1>INFORMATION SHARING WITH THIRD PARTIES</h1>
                            <p>All private information provided to the Website will be handled in accordance with
                                privacy laws. It will only be used or shared if consented to by the user, and only in
                                relation to Services offered, or to fulfill business obligations.</p>
                            <p>Data may be processed or shared based on the following legal basis:</p>
                            <ul>
                                <li>
                                    <strong>Consent:</strong> Data may only be processed for a specific purpose when the
                                    User gives the Website consent to do so.
                                </li>
                                <li>
                                    <strong>Legitimate Interests: </strong>for legitimate business interests.
                                </li>
                                <li>
                                    <strong>Performance of a Contract:</strong> to fulfill the terms of the contract
                                    between the User and the Website.
                                </li>
                                <li>
                                    <strong>Legal Obligations:</strong> In the event the Website is legally obliged to
                                    disclose information, this may include governmental requests, a judicial proceeding,
                                    court order, or legal process, a court order or a subpoena, public authorities, or
                                    for national security or law enforcement requirements.
                                </li>
                                <li>
                                    <strong>Vital Interests:</strong> In the event it is necessary to investigate,
                                    prevent, or take action regarding potential violations of our policies, suspected
                                    fraud, situations involving potential threats to the safety of any person and
                                    illegal activities, or as evidence in litigation in which we are involved.
                                </li>
                            </ul>

                            <h1>4. USE OF COOKIES AND OTHER TECHNOLOGIES</h1>
                            <p>The Website may use cookies and similar tracking technologies to access or store
                                information. Please refer to Cookie Notice for further information.</p>

                            <h1>5. USE OF GOOGLE MAPS AND/OR PLATFORM APIS</h1>
                            <p>This Website uses Google Maps Platform APIs which are subject to Google’s Terms of
                                Service. You may find the Google Maps Platform Terms of Service <a
                                    href="https://cloud.google.com/maps-platform/terms">here</a>. To find out more about
                                Google’s Privacy Policy, please refer to <a href="https://policies.google.com/privacy">this
                                    link</a>.</p>

                            <h1>6. PRIVATE SOCIAL LOGINS</h1>
                            <p>The User may access the Services provided by the Website using their personal third-
                                party social media accounts (Facebook, Twitter, or other platforms). The User herein
                                acknowledges that should they choose to access the Website through a third party
                                social media platform, the Website will receive certain profile information from the
                                social media provider. The information received may include name, email address,
                                friends list, profile picture as well as other information you choose to make public on
                                such a social media platform. Information will vary depending on the third party social
                                media provider concerned.</p>
                            <p>Information provided by the third party social media platform will only be used by the
                                Website for purposes described in this privacy notice or that are otherwise made clear
                                to you on the relevant Website. The Website is not responsible nor does it control other
                                uses of the personal information provided by the user to the third-party social media
                                provider.</p>
                            <h1>7. LENGTH OF STORAGE OF INFORMATION</h1>
                            <p>The Website will only store data for as long as it is necessary for the purposes set out
                                in this privacy notice, barring any requirement permitted by law that may include tax
                                purposes, accounting or other legal requirements. Any and all information provided by
                                the User to the Website will be deleted upon dissolution of contract between the two
                                parties that includes the user terminating the use of the Services provided, and no
                                further ongoing legitimate business.</p>
                            <p>All personal information will be deleted or anonymized. In the event this is not possible
                                due to information being stored in backup archives, it will be securely stored until
                                deletion is possible.</p>
                            <h1>8. YOUR INFORMATION IS SAFE WITH US</h1>
                            <p>The Website has applied safety measures to protect all personal information
                                processed. However, despite the Website’s efforts, the User must acknowledge that no
                                interaction on the Internet is 100% safe. Therefore, the Website cannot promise or
                                guarantee that hackers, cybercriminals, or other unauthorized third parties will not be
                                able to defeat security measures implemented by the Website, and improperly collect,
                                access, steal, or modify any stolen information. Therefore, transmission of personal
                                information to and from the Website is done at the User’s own risk. The User must
                                always access the Website within a secure environment.</p>

                            <h1>9. INFORMATION FROM MINORS</h1>
                            <p>The Website does not knowingly solicit data from or market to children under 18 years
                                of age. All users of the Website must be at least 18, or be the parent or guardian and
                                consent to such minor dependent’s use of the Website. If the Website gains knowledge
                                that any information collected belongs to a minor, it will take immediate action such as
                                deactivation of account and deletion af all information of said minor from the Website’s
                                archives. If a user has information that The Website may have collected from children
                                under age 18, please contact us at support@secret-sms.com.</p>
                            <h1>10. YOUR PRIVACY RIGHTS</h1>
                            <p>The Website abides by the rights the user has under the applicable data protection
                                laws such as:</p>
                            <ul>
                                <li>the request to access and obtain a copy of the Users personal information</li>
                                <li>to request rectification or erasure</li>
                                <li>to restrict the processing of personal information provided</li>
                                <li>if applicable, to data portability, and the right to object to the processing of
                                    said
                                    personal information
                                </li>
                            </ul>
                            <p>Any and all requests pertaining to the above mentioned must be made to the Website
                                using the contact us page. All requests will be considered in accordance with
                                applicable data protection laws of the region where the User resides.</p>
                            <p>The User has the right to deny the Website to process their personal information.
                                Consent can be withdrawn at any time. However, termination of consent is not
                                retroactive. Therefore, it will not affect the lawfulness of the processing before its
                                withdrawal. Also it will nor affect the processing of personal information conducted in
                                reliance on lawful processing grounds other than consent.</p>
                            <p>For further information, or if you believe the Website is in any way illegally processing
                                personal information, please contact us at support@secret-sms. </p>
                            <h3>Account Information</h3>
                            <p>To review, modify or terminate an account, please follow these instructions:</p>
                            <ul>
                                <li>To review or modify, simply log in to the account settings and update the user
                                    account.
                                </li>
                                <li>To terminate or delete an account simply contact customer service. Upon
                                    receiving this request the Website will deactivate or delete the account,
                                    including all information from active databases. However, The Website is
                                    entitled to retain certain information to prevent fraud, troubleshoot problems,
                                    assist with any investigations, enforce our Terms of Use, and/or comply with
                                    applicable legal requirements.
                                </li>
                            </ul>
                            <p>Most web browsers are set to accept cookies by default. The User can choose to
                                remove all cookies from the browser. However, this may affect certain features or
                                services of our Website.</p>
                            <p>The User can unsubscribe from our marketing email list at any time by clicking on the
                                unsubscribe link included in the emails sent, or by using the contact information
                                provided. However, the Website may still use email to communicate service related, or
                                administration issues regarding the users account. we may still communicate with you,
                                for example, to send you service-related emails that are necessary for the
                                administration and use of your account, to respond to service requests, or for other
                                non-marketing purposes.</p>

                            <h1>11. Controls for Do-Not-Track Features</h1>
                            <p>Most web browsers and some mobile operating systems and mobile applications
                                include a Do-Not-Track (DNT) feature. The User can modify their preference at any
                                time on the device used. Please be aware that the Website does not currently respond
                                to DNT browser signals or any other mechanism that automatically communicates
                                between the users device and their choice not to be tracked online. Should a standard
                                for online tracking be adopted in the future, the Website will promptly inform the user
                                of such changes.</p>
                            <h1>12. PRIVACY POLICY UPDATES</h1>
                            <p>The Website reserves its right to update this privacy notice from time to time. The
                                updated version will be indicated by an updated Revised date at the top of this page.
                                Any update made to this notice will be made effective when it is accessible.</p>
                            <p>Should the Website make any material changes to this notice it will promptly inform all
                                users by sending a notification.</p>

                            <h3>13. Contact Us About This Notice</h3>
                            <p>If you have questions or comments about this notice, please contact us at
                                support@secret-sms.com.</p>
                            <h3>14. REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU</h3>
                            <p>Based on the applicable laws of the country of residence of the User, they may have
                                the right to request access to the personal information collected, change that
                                information, or delete it in some circumstances. Users can request to review, update, or
                                delete their personal information, by accessing their private profile. The website will
                                respond to any request within 30 days.</p>


                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default PrivacyPolicy;
