import React, {useContext} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import Logo from 'common/components/UIElements/Logo';
import Box from 'common/components/Box';
import HamburgMenu from 'common/components/HamburgMenu';
import Container from 'common/components/UI/Container';
import {DrawerContext} from 'common/contexts/DrawerContext';

import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

import LogoImage from 'common/assets/image/app-minimal/header-logo.png';
import LogoImageStick from 'common/assets/image/app-minimal/header-logo-stick.png';

const NavbarOut = ({navbarStyle, logoStyle, button, row, menuWrapper}) => {
    const {state, dispatch} = useContext(DrawerContext);

    // Toggle drawer
    const toggleHandler = () => {
        dispatch({
            type: 'TOGGLE',
        });
    };

    const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

    return (
        <NavbarWrapper {...navbarStyle}>
            <Container>
                <Box {...row}>
                    <Logo
                        href="/"
                        logoSrc={LogoImage}
                        title="Agency"
                        logoStyle={logoStyle}
                        className="main-logo"
                        style={{'marginTop': '0px'}}
                    />
                    <Logo
                        href="/"
                        logoSrc={LogoImageStick}
                        title="Agency"
                        logoStyle={logoStyle}
                        className="stricky-logo"
                    />
                    <Box {...menuWrapper} className="mainMenuWrapper">
                        <ul className="scrollspy__menu main_menu">
                            <li className=""><a href="/">Home</a></li>
                            <li className=""><a href="/faq">FAQ</a></li>
                            <li className="mobile-fixed-links"><a href="/login">Login</a></li>
                        </ul>
                        <a href={process.env.APP_HOST + "/login"} className="navbar_button">
                            <Button {...button} title="Unsubscribe"/>
                        </a>
                        <a href={process.env.APP_HOST + "/login"} className="navbar_button_two">
                            <Button {...button} title="Login"/>
                        </a>
                        <Drawer
                            width="420px"
                            placement="right"
                            drawerHandler={<HamburgMenu barColor="#108AFF"/>}
                            open={state.isOpen}
                            toggleHandler={toggleHandler}
                        >
                            <ScrollSpyMenu
                                className="mobile_menu"
                                menuItems={Data.appMinimalJson.MENU_ITEMS}
                                drawerClose={true}
                                offset={-100}
                            />
                        </Drawer>
                    </Box>
                </Box>
            </Container>
        </NavbarWrapper>
    );
};

NavbarOut.propTypes = {
    navbarStyle: PropTypes.object,
    logoStyle: PropTypes.object,
    button: PropTypes.object,
    row: PropTypes.object,
    menuWrapper: PropTypes.object,
};

NavbarOut.defaultProps = {
    navbarStyle: {
        className: 'sassminimal_navbar',
        minHeight: '70px',
        display: 'block',
    },
    row: {
        flexBox: true,
        alignItems: 'center',
        width: '100%',
    },
    logoStyle: {
        maxWidth: ['180px', '180px'],
        marginTop: '6px'
    },
    button: {
        type: 'button',
        fontSize: '13px',
        fontWeight: '600',
        color: 'white',
        borderRadius: '4px',
        pl: '15px',
        pr: '15px',
        colors: 'primaryWithBg',
        minHeight: 'auto',
        height: `${1}`,
    },
    menuWrapper: {
        flexBox: true,
        alignItems: 'center',
    },
};

export default NavbarOut;
